export default [
    {
        name: 'Adventure',
        url: require('@/assets/phone2/Adventure.png'),
    },
    {
        name: 'Boys',
        url: require('@/assets/phone2/Boys.png'),
    },
    {
        name: 'Girls',
        url: require('@/assets/phone2/Girls.png'),
    },
    {
        name: 'Casual',
        url: require('@/assets/phone2/Casual.png'),
    }
]